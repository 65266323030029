import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate'

import teleskop_00 from '../../images/teleskopschiebetuer_00_1600.webp'
import teleskop_01 from '../../images/teleskopschiebetuer_01_1600.webp'
import teleskop_02 from '../../images/teleskopschiebetuer_02_1600.webp'
import teleskop_03 from '../../images/teleskopschiebetuer_03_1600.webp'
import teleskop_thumb_00 from '../../images/teleskopschiebetuer_00_400.webp'
import teleskop_thumb_01 from '../../images/teleskopschiebetuer_01_400.webp'
import teleskop_thumb_02 from '../../images/teleskopschiebetuer_02_400.webp'
import teleskop_thumb_03 from '../../images/teleskopschiebetuer_03_400.webp'

const context = {
  title: 'Automatische Teleskop&#173;schiebetür',
  description: 'Größte Öffnungsweite, hygenisch, sicher und komfortabel',
  text: 'Eine automatische Teleskopschiebetüre besteht entweder aus zwei gleichöffnenden oder vier beidseitig öffnenden Türflügeln sowie einer Antriebs- und Sensorikeinheit. Sie kann beispielsweise in ein Rahmenelement (Festverglasung) oder in ein Mauerwerk eingefasst werden. Die Einbaumaße bewegen sich meist in einer lichten Einbaubreite von 900 mm bis 3800 mm. \n\n' +

  'Die automatischen Teleskopschiebetüren ermöglichen es, Eingangssituationen barrierefrei, hygienisch und zeitgemäß zu gestalten. Sie wird meist dann eingebaut, wenn die lichte Einbaubreite einer standardmäßigen Automatiktüre nicht ausreicht bzw. in der gegebenen Einbausituation die größtmögliche Durchgangsbreite erreicht werden soll. \n\n' +

  'Nicht von der Stange -  jede Automatiktür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten ineinandergreifen kann eine langfristig zufriedenstellende Eingangssituation entstehen. Um diese nach Ihren Vorstellungen und Wünschen technisch und optisch perfekt zu gestalten, legen wir von Automatiktür24 größten Wert auf eine smarte Beratung und kompetente Umsetzung.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    //'breakOut',
    //'design',
    //'din18650',
    //'energieeinsparung',
    //'modernisierung',
    'notStrom',
    'personenfrequenz',
    'platzsparend',
    //'transparenz',
    //'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: teleskop_00,
      thumbnail: teleskop_thumb_00,
      originalAlt: 'Teleskopschiebetür'
    },
    {
      original: teleskop_01,
      thumbnail: teleskop_thumb_01,
      originalAlt: 'Teleskopschiebetür'
    },
    {
      original: teleskop_02,
      thumbnail: teleskop_thumb_02,
      originalAlt: 'Teleskopschiebetür'
    },
    {
      original: teleskop_03,
      thumbnail: teleskop_thumb_03,
      originalAlt: 'Teleskopschiebetür'
    },
  ],
  configurator: true,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
